.content-wrapper{
    background: #03050e url(../images/auth-bg.png) no-repeat fixed right top !important;
}
.auth .auth-form-light,
.navbar,
.sidebar,
.navbar .navbar-brand-wrapper,
.footer,
.card,
.rdt_Table,
.rdt_TableHeadRow,
.rdt_TableRow,
.rdt_Pagination,
.modal-content,
.dropdown-menu{
    background: #0b0e13 !important;
}
.modal-content{
    border-color: #0b0e13 !important;
}
.auth form .form-group .form-control, .auth form .form-group .swal2-modal .swal2-content .swal2-input, .swal2-modal .swal2-content .auth form .form-group .swal2-input{
    border-color: #1a2029 !important;
}
.brand-logo h2{
    color: #ffffff !important;
}
.auth-form-light h4,
.auth-form-light h6,
.sidebar .nav .nav-item .nav-link .menu-title{
    color: #bcbcbc !important;
}
.btn-primary, .custom-file .custom-file-label::after, .form-wizard .container > div:not(.content) > button, .react-bootstrap-table-pagination .react-bs-table-sizePerPage-dropdown .dropdown-toggle, .fc .fc-button-group .fc-button-primary.fc-button-active:not(:disabled), .fc .fc-header-toolbar .fc-left .fc-today-button{
    background-color: #15b383 !important;
    border-color: #15b383 !important;
}
.auth{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.form-control, .swal2-modal .swal2-content .swal2-input, .swal2-modal .swal2-content .swal2-input{
    color: #ffffff !important;
}
.form-control{
    background: #1a2029 !important;
    border-color: #1a2029 !important;
}
.footer{
    border-top: 0 !important;
}
.rdt_TableRow,
.rdt_TableHead{
    color: #ffffff !important;
}
.navbar .navbar-brand-wrapper .navbar-brand img{
    width: auto !important;
}
.sidebar .nav .nav-item.active .nav-link .menu-title,
.sidebar .nav .nav-item.active .nav-link i,
.sidebar .nav.sub-menu .nav-item .nav-link.active{
    color: #15b383 !important;
}
.sidebar .nav .nav-item:hover,
.dropdown-item:hover, .dropdown-item:focus{
    background-color: #1a2029 !important;
}
.sidebar .nav.sub-menu .nav-item .nav-link:hover {
    color: #ffffff !important;
  }
  .btn-gradient-primary,
  .bg-gradient-primary, #theme-settings .settings-close,.btn-gradient-info {
    background: linear-gradient(to right, #226be2, #15b383) !important;
  }
  .card-title,
  .modal-title{
    color: #15b383 !important;
  }
  .rdt_TableHeadRow,
  .rdt_TableRow{
    border-bottom-color: #1a2029 !important;
  }
  .table{
    color: #ffffff !important;
  }
  .table thead th,
  .table td,
  .modal-header{
    border-bottom: 1px solid #1a2029 !important;
  }
  .modal-footer{
    border-top-color: #1a2029 !important;
  }
  .rdt_Pagination{
    border-top-color: #1a2029 !important;
    color: #ffffff !important;
  }
  .rdt_TableBody a,
  .card-body .form-group a{
    color: #15b383 !important;
  }
  .card-body label,
  .form-label,
  .dropdown-item{
    color: #b5b5b5 !important;
  }
  .card-body h5{
    color: #15b383 !important;
  }
  .bg-gradient-primary .card-body h5{
    color: #ffffff !important;
  }
  .card-body .form-group h5{
    color: #ffffff !important;
  }
  .table-hover tbody tr:hover {
    color: #ffffff;
    background: transparent !important;
  }
  .long-column{
    width: 50% !important;
    white-space: unset !important;
  }
  .modal-header .close{
    color: #ffffff !important;
  }
  .dropdown .btn.btn-primary{
    border-radius: 0 !important;
    background: transparent !important;
    color: #ffffff !important;
  }
  .nav-profile-text p{
    color: #ffffff !important;
    margin-bottom: 0 !important;
  }
  .navbar .navbar-menu-wrapper .navbar-nav .nav-item .dropdown .dropdown-toggle::after,
  .text-primary, .list-wrapper .completed .remove {
    color: #15b383 !important;
  }